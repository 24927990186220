import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
/* eslint-enable */

import PropTypes from 'prop-types';
import footer from '../components/Shared/footer';
import Dynamic from '../components/Dynamic';
import Layout from '../components/layout';
import SEO from '../components/seo';
import marketo from '../utils/marketo.munchkin';
import killServiceWorkers from '../utils/killServiceWorkers';
import bodyTagId from '../utils/bodyTagId';
import generateFAQSchema from '../utils/generateFAQSchema';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

const page1 = ({ data }) => {
  // Google Structured Data
  (function GoogleStructuredData() {
    useEffect(() => {
      if (data.dynamicPage.googleFaqSchema) {
        generateFAQSchema();
      }
    }, []);
  })();

  return (
    <Layout
      navbarType={data.dynamicPage.navbarType}
      navbarBackground={data.dynamicPage.navbarBackground}
    >
      {bodyTagId(data.dynamicPage.bodyTagId)}
      <SEO
        title={data.dynamicPage.title}
        description={data.dynamicPage.description}
        url={data.dynamicPage.url}
        image={data.dynamicPage.seoPreviewImg}
        canonical={data.dynamicPage.canonical}
        noindex={data.dynamicPage.noindex}
      />
      {JSON.parse(data.dynamicPage.content).map((item) => (
        <div key={pmUuid()}>
          {Dynamic[item.type]
            ? Dynamic[item.type](item, data.dynamicPage.sdk)
            : ''}
        </div>
      ))}
      {JSON.parse(data.globalValues.content).map((item) => {
        if (
          item.type === 'footer' &&
          data.dynamicPage.disableFooter === false
        ) {
          return <div key={pmUuid()}>{footer(item)}</div>;
        }
        return <React.Fragment key={pmUuid()} />;
      })}
      {marketo()}
      {killServiceWorkers()}
    </Layout>
  );
};

export default page1;

export const query = graphql`
  query ($id: String!) {
    dynamicPage(id: { eq: $id }) {
      id
      url
      content
      description
      title
      sdk
      seoPreviewImg
      canonical
      noindex
      navbarType
      navbarBackground
      bodyTagId
      googleFaqSchema
      disableFooter
    }
    globalValues {
      content
    }
  }
`;

page1.propTypes = {
  data: PropTypes.shape({
    dynamicPage: PropTypes.shape({
      content: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      url: PropTypes.string,
      canonical: PropTypes.string,
      noindex: PropTypes.bool,
      navbarType: PropTypes.string,
      navbarBackground: PropTypes.string,
      googleFaqSchema: PropTypes.bool,
      disableFooter: PropTypes.bool
    }),
    globalValues: PropTypes.shape({
      content: PropTypes.string
    })
  }).isRequired
};
